<template>
    <b-row>
        <b-col lg="12" sm="12">
            <b-overlay :show="loader">
                <list-report-head :base-url="baseUrl" uri="/itf-configuration/report-head/detail" :org-id="2">
                    <template v-slot:projectNameSlot>
                    {{ }}
                    </template>
                    {{ $t('globalTrans.payment') }}
                </list-report-head>
                <b-row>
                    <div class="col-md-10 offset-md-1" id="moneyReceiptArea">
                        <table v-if="paymentData" class="table table-bordered table-sm">
                            <tbody>
                            <tr>
                                <th colspan="2">{{  $t('dealer.company_name') }}</th>
                                <td colspan="2">{{ $i18n.locale === 'bn' ? paymentData.app_main?.comp_name_bn : paymentData.app_main?.comp_name_en }}</td>
                            </tr>
                            <tr>
                                <th colspan="2">{{  $t('globalTrans.payment_type') }}</th>
                                <td colspan="2">{{ getPayType(paymentData.payment_type) }}</td>
                            </tr>
                            <tr>
                                <th colspan="2">{{  $t('globalTrans.pay_status') }}</th>
                                <td colspan="2">
                                    <span class="badge badge-danger" v-if="paymentData.app_main.pay_status === 0">{{$t('globalTrans.unpaid')}}</span>
                                    <span class="badge badge-warning" v-if="paymentData.app_main.pay_status === 1">{{$t('globalTrans.processing_counter')}}</span>
                                    <span class="badge badge-success" v-else>{{$t('globalTrans.paid')}}</span>
                                </td>
                            </tr>
                            <tr v-if="paymentData.sonali_transaction_id && paymentData.app_main.pay_status === 1">
                                <th colspan="2">{{  $t('globalTrans.print_voucher') }}</th>
                                <td colspan="2">
                                    <a target="_blank" :href="voucherDownload(paymentData.sonali_transaction_id)"><i class="ri-file-download-fill"></i></a>
                                </td>
                            </tr>
                            <tr v-if="paymentData.sonali_transaction_id && paymentData.app_main.pay_status === 2">
                                <th colspan="2">{{  $t('globalTrans.print_voucher') }}</th>
                                <td colspan="2">
                                    <a target="_blank" :href="voucherDownload2(paymentData.sonali_transaction_id)"><i class="ri-file-download-fill"></i></a>
                                </td>
                            </tr>
                            <tr v-if="paymentData.payment_type == 1">
                                <th colspan="2">{{  $t('globalTrans.voucher_no') }}</th>
                                <td colspan="2">{{ paymentData.voucher_no }}</td>
                            </tr>
                            <tr v-if="paymentData.payment_type == 2">
                                <th colspan="2">{{  $t('globalTrans.transaction_no') }}</th>
                                <td colspan="2">{{ paymentData.transaction_no }}</td>
                            </tr>
                            <tr v-if="paymentData.pay_status === 2">
                                <th colspan="2">{{  $t('globalTrans.date') }}</th>
                                <td colspan="2">{{ paymentData.pay_date | dateFormat }}</td>
                            </tr>
                            <tr v-if="paymentData.payment_type == 1">
                                <th colspan="2">{{  $t('externalUser.bank_name') }}</th>
                                <td colspan="2">{{ getBankName(paymentData.bank_id) }}</td>
                            </tr>
                            <tr v-if="paymentData.payment_type == 1">
                                <th colspan="2">{{  $t('externalUser.branch_name') }}</th>
                                <!-- <td colspan="2">{{ getBranchName(paymentData.branch_id) }}</td> -->
                                <td colspan="2">{{ paymentData.branch_name }}</td>
                            </tr>
                            <tr v-if="paymentData.payment_type == 1">
                                <th colspan="2">{{  $t('externalTradeFair.check_deposit') }}</th>
                                <td colspan="2">
                                    <a target="_blank" v-if="paymentData.attachment"
                                        :href="baseUrl + 'storage/' + paymentData.attachment">
                                        <i class="ri-download-cloud-fill download-btn" :title="$t('globalTrans.download')"></i>
                                    </a>
                                </td>
                            </tr>
                            <tr>
                                <th colspan="4" class="text-center">{{ $t('dealer.payment_history') }}</th>
                            </tr>
                            <tr>
                                <th class="text-center">{{ $t('globalTrans.sl_no') }}</th>
                                <th class="text-center">{{ $t('tradeFairConfig.stall_cat_name') }}</th>
                                <th v-if="circular_type === 1" class="text-center">{{ $t('externalTradeFair.security_money') }}</th>
                                <th v-if="circular_type === 2" class="text-center">{{ $t('externalTradeFair.quoted_price') }}</th>
                                <th class="text-center">{{ $t('externalTradeFair.processing_fee') }}</th>
                            </tr>
                            <tr v-for="(item, index) in stalls" :key="index">
                                <td class="text-center">{{ $n(index + 1) }}</td>
                                <td class="text-center">{{ getStallCategoryName(item.stall_cat_id) }}</td>
                                <td v-if="circular_type === 1" class="text-right">{{ $n(getSecurityMoney(item.quoted_price), { minimumFractionDigits: 2 }) }}</td>
                                <td v-if="circular_type === 2" class="text-right">{{ $n(item.quoted_price, { minimumFractionDigits: 2 }) }}</td>
                                <td class="text-right">{{ $n(getProcessFee(item.stall_cat_id), { minimumFractionDigits: 2 }) }}</td>
                            </tr>
                            <tr>
                                <th class="text-right" colspan="2">{{ $t('globalTrans.total') }}</th>
                                <td class="text-right">{{ $n(paymentData.total_sec_money, { minimumFractionDigits: 2 }) }}</td>
                                <td class="text-right">{{ $n(paymentData.total_app_fee, { minimumFractionDigits: 2 }) }}</td>
                            </tr>
                            <tr>
                                <th class="text-right" colspan="2">{{ $t('eBizProgram.grand_total') }} {{ $t('sitePreference.grandTotal') + ' ' + $t('externalTradeFair.payable') }}</th>
                                <td colspan="2" class="text-right">{{ $n(paymentData.total_money, { minimumFractionDigits: 2 }) }}</td>
                            </tr>
                            </tbody>
                        </table>
                        <slot v-else>
                            <b-tr>
                                <td colspan="4" class="text-center">Sorry, Data not found</td>
                            </b-tr>
                        </slot>
                        <b-col class="text-right">
                            <b-button variant="danger" type="button" class="btn-sm" @click="$bvModal.hide('payment-receipt')">{{ $t('globalTrans.cancel') }}</b-button>
                        </b-col>
                    </div>
                </b-row>
            </b-overlay>
        </b-col>
    </b-row>
</template>
<script>
import RestApi, { internationalTradeFairServiceBaseUrl } from '@/config/api_config'
import { DitfPaymentReceipt } from '../../../../api/routes'
import ListReportHead from '@/components/custom/ListReportHead.vue'

export default {
    name: 'Form',
    props: ['id', 'circular_type', 'year'],
    components: {
        ListReportHead
    },
    created () {
        if (this.id) {
            this.getPayInfo()
        }
    },
    data () {
        return {
            baseUrl: internationalTradeFairServiceBaseUrl,
            loader: false,
            valid: null,
            totalSecurityMoney: 0,
            totalProcessFee: 0,
            stalls: [],
            paymentData: [],
            paymentTypeList: [
                { text: this.$i18n.locale === 'bn' ? 'অনলাইন' : 'Online', value: 2 },
                { text: this.$i18n.locale === 'bn' ? 'অফলাইন' : 'Offline', value: 1 }
            ]
        }
    },
    watch: {
    },
    computed: {
        bankList () {
            return this.$store.state.CommonService.commonObj.bankList.filter(item => item.status === 1)
        },
        currentLocale () {
        return this.$i18n.locale
        }
    },
    methods: {
        voucherDownload (voucher) {
            return 'https://voucher.sblesheba.com:8877/CounterReceipt/' + voucher
        },
        voucherDownload2 (voucher) {
            return 'https://voucher.sblesheba.com:8877/Receipt/' + voucher
        },
        getPayType (id) {
            const dataPayType = this.paymentTypeList.find(item => item.value === parseInt(id))
            if (typeof dataPayType !== 'undefined') {
            return dataPayType.text
            } else {
            return ''
            }
        },
        getProcessFee (id) {
            const Obj = this.$store.state.ExternalUserService.tradeFair.ditfCommonObj.stallCategoryList.find(item => parseInt(item.value) === parseInt(id))
            return Obj !== undefined ? Obj.processing_fee : 0
        },
        getStallCategoryName (id) {
            const Obj = this.$store.state.ExternalUserService.tradeFair.ditfCommonObj.stallCategoryList.find(item => parseInt(item.value) === parseInt(id))
            if (this.currentLocale === 'bn') {
            return Obj !== undefined ? Obj.text_bn : ''
            } else {
            return Obj !== undefined ? Obj.text_en : ''
            }
        },
        getBankName (id) {
            const Obj = this.$store.state.CommonService.commonObj.bankList.find(item => parseInt(item.value) === parseInt(id))
            if (this.currentLocale === 'bn') {
            return Obj !== undefined ? Obj.text_bn : ''
            } else {
            return Obj !== undefined ? Obj.text_en : ''
            }
        },
        getBranchName (id) {
            const Obj = this.$store.state.CommonService.commonObj.branchList.find(item => parseInt(item.value) === parseInt(id))
            if (this.currentLocale === 'bn') {
            return Obj !== undefined ? Obj.text_bn : ''
            } else {
            return Obj !== undefined ? Obj.text_en : ''
            }
        },
        getSecurityMoney (data) {
            const Obj = this.$store.state.ExternalUserService.tradeFair.ditfCommonObj.securityMoneyList.find(item => item.year === this.year)
            const percentage = Obj !== undefined ? Obj.percentage : 0
            return (percentage * data) / 100
        },
        async getPayInfo () {
            this.loader = true
            const myObj = {
                app_main_id: this.id,
                circular_type: this.circular_type
            }
            const result = await RestApi.getData(internationalTradeFairServiceBaseUrl, DitfPaymentReceipt, myObj)
            this.loader = false
            if (result.success) {
                this.stalls = result.data
                this.paymentData = result.paymentData
            }
        },
        async pdfExport () {
            this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
            const params = Object.assign({ request_type: 'pdf', local: this.$i18n.locale, org_id: 2, app_main_id: this.id, circular_type: this.circular_type, year: this.year })
            const result = await RestApi.getPdfData(internationalTradeFairServiceBaseUrl, DitfPaymentReceipt, params)
            var blob = new Blob([result], {
                type: 'application/pdf'
            })
            var url = window.URL.createObjectURL(blob)
            window.open(url).print()
            this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
        }
    }
}
</script>
