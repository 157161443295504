<template>
    <b-row>
        <b-col lg="12" sm="12">
            <b-overlay :show="loader">
                <list-report-head :base-url="baseUrl" uri="/itf-configuration/report-head/detail" :org-id="2">
                    <template v-slot:projectNameSlot>
                    {{ }}
                    </template>
                    {{ $t('globalTrans.payment') }}
                </list-report-head>
                <b-row>
                    <b-table-simple v-if="circular_type === 1" bordered small>
                        <thead>
                            <tr>
                                <b-th colspan="7" class="text-white text-center p-2 bg-dark">{{ $t('tradeFairConfig.stall_information') }}</b-th>
                            </tr>
                            <tr class="bg-primary text-center text-align-center">
                                <th style="width:10%">{{ $t('globalTrans.sl_no') }}</th>
                                <th style="width:20%">{{ $t('tradeFairConfig.stall_cat_name') }}</th>
                                <th style="width:15%">{{ $t('ditfConfig.stall_no') }}</th>
                                <th style="width:15%">{{ $t('externalTradeFair.floor_price') }}</th>
                                <th style="width:15%">{{ $t('externalTradeFair.quoted_price') }}</th>
                                <th style="width:15%">{{ $t('externalTradeFair.security_money') }}</th>
                                <th style="width:15%">{{ $t('externalTradeFair.processing_fee') }}</th>
                            </tr>
                        </thead>
                        <b-tbody>
                            <span hidden>{{ $si = 1 }}</span>
                            <template v-for="(items, index) in stalls">
                                <b-tr v-for="(item, index1) in items" :key="index+index1">
                                    <b-td v-if="index1 === 0" class="align-middle text-center" :rowspan="items.length">{{ $n($si++) }}</b-td>
                                    <b-td v-if="index1 === 0" :rowspan="items.length" class="align-middle text-center">{{ getStallCategoryName(index) }}</b-td>
                                    <b-td class="text-center">{{ item.stall_info.stall_no }}</b-td>
                                    <b-td v-if="index1 === 0" :rowspan="items.length" class="align-middle text-center">{{ $n(getFloorPrice(index), { minimumFractionDigits: 2 }) }}</b-td>
                                    <b-td class="text-center">{{ $n(item.quoted_price, { minimumFractionDigits: 2 }) }}</b-td>
                                    <b-td v-if="index1 === 0" :rowspan="items.length" class="align-middle text-center">{{ $n(getSecurityMoney(items[0]['quoted_price']), { minimumFractionDigits: 2 }) }}</b-td>
                                    <b-td v-if="index1 === 0" :rowspan="items.length" class="align-middle text-center">{{$n(getProcessFee(index), { minimumFractionDigits: 2 }) }}</b-td>
                                </b-tr>
                            </template>
                            <b-tr>
                                <b-td colspan="5" class="text-right">{{ $t('globalTrans.total') }}</b-td>
                                <b-td class="text-center">{{ $n(getTotalSMoney(stalls), { minimumFractionDigits: 2 }) }}</b-td>
                                <b-td class="text-center">{{ $n(getTotalProcessFee(stalls), { minimumFractionDigits: 2 }) }}</b-td>
                            </b-tr>
                            <b-tr>
                                <b-td colspan="5" class="text-right">{{ $t('sitePreference.grandTotal') + ' ' + $t('externalTradeFair.payable') }}</b-td>
                                <b-td colspan="2" class="text-center">{{ $n((totalSecurityMoney + totalProcessFee), { minimumFractionDigits: 2 }) }}</b-td>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>
                    <b-table-simple v-if="circular_type === 2" bordered small>
                        <thead>
                            <tr>
                                <b-th colspan="5" class="text-white text-center p-2 bg-dark">{{ $t('tradeFairConfig.stall_information') }}</b-th>
                            </tr>
                            <tr class="bg-primary text-center text-align-center">
                                <th style="width:10%">{{ $t('globalTrans.sl_no') }}</th>
                                <th style="width:20%">{{ $t('tradeFairConfig.stall_cat_name') }}</th>
                                <th style="width:15%">{{ $t('externalTradeFair.floor_price') }}</th>
                                <th style="width:15%">{{ $t('externalTradeFair.quoted_price') }}</th>
                                <th style="width:15%">{{ $t('externalTradeFair.processing_fee') }}</th>
                            </tr>
                        </thead>
                        <b-tbody>
                            <span hidden>{{ $si = 1 }}</span>
                            <b-tr v-for="(item, index) in stalls" :key="index">
                                <b-td class="align-middle text-center">{{ $n($si++) }}</b-td>
                                <b-td class="align-middle text-center">{{ getStallCategoryName(item.stall_cat_id) }}</b-td>
                                <b-td class="align-middle text-center">{{ $n(getFloorPrice(item.stall_cat_id), { minimumFractionDigits: 2 }) }}</b-td>
                                <b-td class="text-center">{{ $n(item.quoted_price, { minimumFractionDigits: 2 }) }}</b-td>
                                <b-td class="align-middle text-center">{{$n(getProcessFee(item.stall_cat_id), { minimumFractionDigits: 2 }) }}</b-td>
                            </b-tr>
                            <b-tr>
                                <b-td colspan="3" class="text-right">{{ $t('globalTrans.total') }}</b-td>
                                <b-td class="text-center">{{ $n(getQuotedPrice(stalls), { minimumFractionDigits: 2 }) }}</b-td>
                                <b-td class="text-center">{{ $n(getOTotalProcessFee(stalls), { minimumFractionDigits: 2 }) }}</b-td>
                            </b-tr>
                            <b-tr>
                                <b-td colspan="3" class="text-right">{{ $t('sitePreference.grandTotal') + ' ' + $t('externalTradeFair.payable') }}</b-td>
                                <b-td colspan="2" class="text-center">{{ $n((totalSecurityMoney + totalProcessFee), { minimumFractionDigits: 2 }) }}</b-td>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>
                </b-row>
                <b-row>
                    <div>
                    </div>
                </b-row>
                <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
                    <b-form @submit.prevent="handleSubmit(saveUpdate)" @reset.prevent="reset" autocomplete="off">
                        <div class="d-flex justify-content-center">
                            <b-card class="payment-wrapper">
                                <p>{{ $t('teaGardenBtriService.payment_option') }}</p>
                                <div class="pay-options">
                                    <input disabled type="radio" v-model="payment.payment_type" :value="2" name="select" v-if="paymentTypeList[0].text_en === 'Bank' || paymentTypeList[1].text_en === 'Bank'" id="bank" :checked="paymentTypeList[0].text_en === 'Bank' ? true : false">

                                    <input type="radio" v-model="payment.payment_type" :value="3" name="select" id="Online" v-if="paymentTypeList[1].text_en === 'Online'">

                                    <label for="bank" class="option bank" v-if="paymentTypeList[0].text_en === 'Bank' || paymentTypeList[1].text_en === 'Bank'">
                                        <div class="dot"></div>
                                        <span v-if="paymentTypeList[0].text_en === 'Bank'">{{ currentLocale === 'en' ? paymentTypeList[0].text_en : paymentTypeList[0].text_bn }}</span>
                                        <span v-if="paymentTypeList[1].text_en === 'Bank'">{{ currentLocale === 'en' ? paymentTypeList[1].text_en : paymentTypeList[1].text_bn }}</span>
                                    </label>

                                    <label for="Online" class="option Online" v-if="paymentTypeList[1].text_en === 'Online'">
                                        <div class="dot"></div>
                                        <span>{{ currentLocale === 'en' ? paymentTypeList[1].text_en : paymentTypeList[1].text_bn }}</span>
                                    </label>
                                </div>
                                <div class="payment-info-boxs">
                                    <!-- <b-col xs="12" style="color: red;">
                                        <h3 class="text-cneter" style="color: black !important;text-align:center">পেমেন্ট সংক্রান্ত নির্দেশাবলী</h3>
                                        <hr style="padding: 0px; border: 1px solid;"/>
                                        <div style="font-size: 15px">
                                            <p>** অনলাইনে ষ্টল বরাদ্দের পেমেন্টের জন্য আবেদনকারী সোনালি ব্যাংকের পেমেন্ট গেটওয়ে “Sonali Payment Gatway” এর মাধ্যমে পেমেন্ট প্রক্রিয়া সমন্ন করতে পারবে। </p>
                                            <p>** আবেদনকারী তার নিজ কার্ড, অ্যাকাউন্ট, ইন্টারনেট ব্যাংকিং এবং মোবাইল ব্যাংকিং এর মাধ্যমে পেমেন্ট প্রক্রিয়া সম্পন্ন করতে পারবে কিন্তু কার্ড এবং অ্যাকাউন্ট পেমেন্ট এর ক্ষেত্রে লিমিট থাকা আবশ্যক যদি পেমেন্ট কার্ড এবং অ্যাকাউন্টে থাকা লিমিট অতিক্রম করে তাহলে কাউন্টার পেমেন্টের মাধ্যমে পেমেন্ট প্রক্রিয়া সম্পূর্ণ করতে পারবে। </p>
                                            <p>** কাউন্টার পেমেন্ট করতে হলে আবেদনকারীকে কাউন্টার অপশন নির্বাচন করতে হবে এবং পেমেন্ট সম্পন্ন করতে হবে। পেমেন্ট সম্পন্ন করার পর আবেদনকারী কাউন্টার ভাউচার পাবে এবং কাউন্টার ভাউচার নিয়ে সোনালী ব্যাংকের যে কোন শাখায় ৭ কার্যদিবসের মধ্যে স্টল বরাদ্দের পেমেন্টটি সম্পন্ন করতে পারবে অন্যথায় পেমেন্টটি বাতিল বলিয়া গণ্য হবে</p>
                                            <p>** মোবাইল ব্যাংকিং এর ক্ষেত্রে সরকার নির্ধারিত তিন লাখের উপরে ট্রানজেকশন করা যাবে না।</p>
                                        </div>
                                    </b-col>
                                    <br/> -->
                                    <b-row v-if="payment.payment_type === 2">
                                        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                            <ValidationProvider :name="$t('externalTradeFair.bank_deposit', 'en')" vid="voucher_no" rules="required" v-slot="{ errors }">
                                                <b-form-group label-for="voucher_no" label-cols-sm="4">
                                                    <template v-slot:label>
                                                        {{$t('externalTradeFair.bank_deposit')}} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-input
                                                        plain
                                                        v-model="payment.voucher_no"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                    >
                                                    </b-form-input>
                                                    <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                            <ValidationProvider :name="$t('globalTrans.date', 'en')" vid="pay_date" rules="required" v-slot="{ errors }">
                                                <b-form-group class="row mb-2"
                                                label-cols-sm="4"
                                                label-for="pay_date"
                                                >
                                                <template v-slot:label>
                                                    {{ $t('globalTrans.date') }} <span class="text-danger">*</span>
                                                </template>
                                                <date-picker
                                                    id="pay_date"
                                                    v-model="payment.pay_date"
                                                    class="form-control"
                                                    :placeholder="$t('globalTrans.select_date')"
                                                    :state="errors[0] ? false : (valid ? true : null)"
                                                    :class="errors[0] ? 'is-invalid' : ''"
                                                >
                                                </date-picker>
                                                <div class="invalid-feedback d-block">
                                                    {{ errors[0] }}
                                                </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                            <ValidationProvider :name="$t('externalUser.bank_name', 'en')" vid="bank_id" rules="required|min_value:1" v-slot="{ errors }">
                                                <b-form-group label-for="bank_id" label-cols-sm="4">
                                                    <template v-slot:label>
                                                        {{$t('externalUser.bank_name')}} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-select
                                                        plain
                                                        v-model="payment.bank_id"
                                                        :options="sortedBankList"
                                                        @change="getBranchList(payment.bank_id)"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                        >
                                                        <template v-slot:first>
                                                            <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                                                        </template>
                                                    </b-form-select>
                                                    <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                            <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                            <ValidationProvider :name="$t('externalUser.branch_name', 'en')" vid="branch_name" rules="required" v-slot="{ errors }">
                                                <b-form-group label-for="branch_name" label-cols-sm="4">
                                                    <template v-slot:label>
                                                        {{$t('externalUser.branch_name')}} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-input
                                                        plain
                                                        v-model="payment.branch_name"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                    >
                                                    </b-form-input>
                                                    <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <!-- <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                            <ValidationProvider :name="$t('externalUser.branch_name', 'en')" vid="branch_id" rules="required|min_value:1" v-slot="{ errors }">
                                                <b-form-group label-for="branch_id" label-cols-sm="4">
                                                    <template v-slot:label>
                                                        {{$t('externalUser.branch_name')}} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-select
                                                        plain
                                                        v-model="payment.branch_id"
                                                        :options="branchList"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                        >
                                                        <template v-slot:first>
                                                            <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                                                        </template>
                                                    </b-form-select>
                                                    <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col> -->
                                        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                            <ValidationProvider :name="$t('externalTradeFair.check_deposit', 'en')" vid="attachment" rules="required" v-slot="{ errors }">
                                                <b-form-group class="row mb-2"
                                                    label-cols-sm="4"
                                                    label-for="attachment">
                                                    <template v-slot:label>
                                                        {{ $t('externalTradeFair.check_deposit')}} <span class="text-danger">*</span>
                                                    </template>
                                                    <b-form-file
                                                        plain
                                                        v-on:change="onFileChange($event)"
                                                        v-model="attachment_input"
                                                        accept=".jpg,.jpeg,.pdf"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                    ></b-form-file>
                                                    <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                    </div>
                                                    <div class="invalid-feedback d-block text-muted">
                                                        {{ $i18n.locale == 'en' ? '[Maximum file size is 500 KB and file format is JPG/JPEG/PDF]' : '[সর্বোচ্চ ফাইল সাইজ 500 KB এবং ফাইল ফরমেট JPG/JPEG/PDF]' }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                        <!-- <b-col xs="12" sm="12" md="12" lg="12" xl="12" class="text-right">
                                            <b-button type="button" @click="finalSave" variant="success" class="mr-2 btn-sm">{{ $t('globalTrans.payment') }}</b-button>
                                            <b-button variant="danger" type="button" class="btn-sm" @click="$bvModal.hide('modal-payment')">{{ $t('globalTrans.cancel') }}</b-button>
                                        </b-col> -->
                                    </b-row>
                                    <b-row>
                                        <b-col cols="12" class="offset-md-4">
                                        <wizard-button @click="finalSave" class="btn btn-success ml-1 mr-1" title="Submit">
                                            <i class="ri-secure-payment-line"></i>
                                            <span v-if="payment.payment_type === 2">
                                            {{$t('globalTrans.submit')}}</span>
                                            <span v-if="payment.payment_type === 3"> {{$t('teaGardenBtriService.online_payment')}}</span>
                                        </wizard-button>
                                        <!-- cancel button  -->
                                        <router-link :to="{name: $route.query.from}" v-if="$route.query.from" class="btn btn-danger ml-1 mr-1 text-white"><i
                                            class="ri-close-circle-line"></i> {{ $t('globalTrans.cancel') }}
                                        </router-link>
                                        </b-col>
                                    </b-row>
                                </div>
                            </b-card>
                        </div>
                    </b-form>
                </ValidationObserver>
            </b-overlay>
        </b-col>
    </b-row>
</template>
<script>
import RestApi, { internationalTradeFairServiceBaseUrl } from '@/config/api_config'
import { DitfPaymentDetails, DitfPaymentStore, DitfOnlinePaymentStore } from '../../../../api/routes'
import ListReportHead from '@/components/custom/ListReportHead.vue'

export default {
    name: 'Form',
    props: ['id', 'circular_type', 'year'],
    components: {
        ListReportHead
    },
    created () {
        if (this.id) {
            this.getPayInfo()
            this.getFairBankId()
        }
    },
    data () {
        return {
            baseUrl: internationalTradeFairServiceBaseUrl,
            loader: false,
            valid: null,
            totalSecurityMoney: 0,
            totalProcessFee: 0,
            // totalMoney: totalSecurityMoney + totalProcessFee,
            stalls: [],
            branchList: [],
            payment: {
                payment_type: 3,
                app_main_id: this.id,
                total_app_fee: this.totalProcessFee,
                total_sec_money: this.totalSecurityMoney,
                total_money: this.totalProcessFee + this.totalSecurityMoney,
                voucher_no: '',
                bank_id: 0,
                branch_id: 0,
                attachment: '',
                pay_date: ''
            },
            details: [],
            sortedBankList: [],
            attachment_input: []
        }
    },
    watch: {
    },
    computed: {
        bankList () {
            return this.$store.state.CommonService.commonObj.bankList.filter(item => item.status === 1)
        },
        paymentTypeList: function () {
                return this.$store.state.TeaGardenService.commonObj.btriPaymentType.filter(item => item.value !== 1)
        },
        currentLocale () {
        return this.$i18n.locale
        }
    },
    methods: {
        getBranchList (Id) {
            this.branchList = this.$store.state.CommonService.commonObj.branchList.filter(item => item.bank_id === parseInt(Id))
        },
        getFloorPrice (id) {
            const Obj = this.$store.state.ExternalUserService.tradeFair.ditfCommonObj.stallCategoryList.find(item => parseInt(item.value) === parseInt(id))
            return Obj !== undefined ? Obj.floor_price : ''
        },
        getProcessFee (id) {
            const Obj = this.$store.state.ExternalUserService.tradeFair.ditfCommonObj.stallCategoryList.find(item => parseInt(item.value) === parseInt(id))
            return Obj !== undefined ? Obj.processing_fee : 0
        },
        getStallCategoryName (id) {
            const Obj = this.$store.state.ExternalUserService.tradeFair.ditfCommonObj.stallCategoryList.find(item => parseInt(item.value) === parseInt(id))
            if (this.currentLocale === 'bn') {
            return Obj !== undefined ? Obj.text_bn : ''
            } else {
            return Obj !== undefined ? Obj.text_en : ''
            }
        },
        getSecurityMoney (data) {
            const Obj = this.$store.state.ExternalUserService.tradeFair.ditfCommonObj.securityMoneyList.find(item => item.year === this.year)
            const percentage = Obj !== undefined ? Obj.percentage : 0
            const amount = (parseFloat(percentage) * parseFloat(data)) / 100
            return parseFloat(amount)
        },
        getQuotedPrice (items) {
            if (items) {
                let quotedPrice = 0
                items.map((item) => {
                    quotedPrice += parseInt(item.quoted_price)
                })
                this.totalSecurityMoney = quotedPrice
                return quotedPrice
            }
        },
        getOTotalProcessFee (items) {
            if (items) {
                let processFee = 0
                items.map((item) => {
                    const Obj = this.$store.state.ExternalUserService.tradeFair.ditfCommonObj.stallCategoryList.find(data => parseInt(data.value) === parseInt(item.stall_cat_id))
                    processFee += parseInt(Obj.processing_fee)
                })
                this.totalProcessFee = processFee
                return processFee
            }
        },
        getTotalSMoney (items) {
            if (items) {
                let sMoney = 0
                const Obj = this.$store.state.ExternalUserService.tradeFair.ditfCommonObj.securityMoneyList.find(item => item.year === this.year)
                const percentage = Obj !== undefined ? Obj.percentage : 0
                Object.keys(items).map((key) => {
                    sMoney += (items[key][0].quoted_price * percentage) / 100
                })
                this.totalSecurityMoney = sMoney
                return sMoney
            }
        },
        getTotalProcessFee (items) {
            if (items) {
                let processFee = 0
                Object.keys(items).map((index) => {
                    const Obj = this.$store.state.ExternalUserService.tradeFair.ditfCommonObj.stallCategoryList.find(item => item.value === parseInt(index))
                    processFee += parseFloat(Obj.processing_fee)
                })
                this.totalProcessFee = processFee
                return processFee
            }
        },
        async getPayInfo () {
            this.details = []
            this.loader = true
            const myObj = {
                app_main_id: this.id,
                circular_type: this.circular_type
            }
            const result = await RestApi.getData(internationalTradeFairServiceBaseUrl, DitfPaymentDetails, myObj)
            this.loader = false
            if (result.success) {
                this.stalls = result.data
                if (this.circular_type === 1) {
                    Object.keys(result.data).forEach(el => {
                        this.details.push({
                            stall_cat_id: el,
                            processing_fee: this.getProcessFee(el),
                            security_money: this.getSecurityMoney(result.data[el][0].quoted_price)
                        })
                    })
                }
            }
        },
        onFileChange (event) {
            const input = event.target
            if (input.files && input.files[0]) {
                this.payment.attachment = event.target.files[0]
            }
        },
        finalSave () {
            this.$swal({
                title: this.$t('externalTradeFair.fair_payment_msg'),
                showCancelButton: true,
                confirmButtonText: this.$t('globalTrans.yes'),
                cancelButtonText: this.$t('globalTrans.no'),
                focusConfirm: false
            }).then((result) => {
                if (result.isConfirmed) {
                    this.payment.total_app_fee = this.totalProcessFee
                    this.payment.total_sec_money = this.totalSecurityMoney
                    this.payment.total_money = (this.totalProcessFee + this.totalSecurityMoney)

                    if (this.payment.payment_type === 3) {
                        this.onlinePayment()
                    } else {
                        this.saveUpdate()
                    }
                }
            })
        },

        async saveUpdate () {
            var check = await this.$refs.form.validate()
            if (check) {
                this.$store.dispatch('mutateCommonProperties', { loading: true })
                let result = null
                this.loader = true
                const loadingState = { loading: false, listReload: false }
                const formData = new FormData()
                Object.keys(this.payment).map(key => {
                    if (key === 'attachment') {
                        formData.append(key, this.attachment_input)
                    } else {
                        formData.append(key, this.payment[key])
                    }
                })
                if (this.circular_type === 1) {
                    formData.append('details', JSON.stringify(this.details))
                }

                if (this.id) {
                    result = await RestApi.postData(internationalTradeFairServiceBaseUrl, DitfPaymentStore, formData)
                }
                loadingState.listReload = true
                this.$store.dispatch('mutateCommonProperties', loadingState)
                this.loader = false
                if (result.success) {
                    this.$store.dispatch('TradeFairService/mutateCommonObj', { hasDropdownLoaded: false })
                    this.$toast.success({
                        title: this.$t('globalTrans.success'),
                        message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
                        color: '#D6E09B'
                    })
                    this.$bvModal.hide('modal-payment')
                } else {
                    this.$refs.form.setErrors(result.errors)
                }
            }
        },

        async onlinePayment () {
            let tmpPay = {}
            const name = this.$store.state.Auth.authUser.name
            const email = this.$store.state.Auth.authUser.email
            const mobile = this.$store.state.Auth.authUser.mobile
            if (this.circular_type === 1) {
                this.payment.details = JSON.stringify(this.details)
            }
            tmpPay = Object.assign(this.payment, { applicant_name: name, email: email, mobile: mobile })
            this.$store.dispatch('mutateCommonProperties', { loading: true })
            let result = null
            this.loader = true
            const loadingState = { loading: false, listReload: false }
            result = await RestApi.postData(internationalTradeFairServiceBaseUrl, DitfOnlinePaymentStore, tmpPay)
            loadingState.listReload = true
            this.loader = false
            this.$store.dispatch('mutateCommonProperties', loadingState)
            if (result.success) {
                    this.$store.dispatch('TradeFairService/mutateCommonObj', { hasDropdownLoaded: false })
                    this.$toast.success({
                        title: this.$t('globalTrans.success'),
                        message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
                        color: '#D6E09B'
                    })
                    window.location = result.url
                    // this.$bvModal.hide('modal-payment')
            } else {
                    this.$refs.form.setErrors(result.errors)
            }
        },

        async getFairBankId () {
            const bankList = this.$store.state.CommonService.commonObj.bankList.filter(item => item.status === 1)
            const result = await RestApi.getData(internationalTradeFairServiceBaseUrl, '/ditf-configuration/bank-info/details', null)
            if (result.success) {
                this.sortedBankList = bankList.filter(item => result.data.includes(item.value))
            } else {
                this.sortedBankList = []
            }
        }
    }
}
</script>
<style scoped>
.p_heading{
      font-weight: bold;
    }

.payment-wrapper {
  background: #fff;
  width: 100%;
  border-radius: 5px;
  padding: 20px 15px;
  box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.2);
}
.pay-options {
  display: inline-flex;
  height: 40px;
  width: 100%;
  align-items: center;
  justify-content: space-evenly;
}

.pay-options .option {
  background: #fff;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  padding: 0 10px;
  border: 1px solid lightgrey;
  transition: all 0.3s ease;
}

.pay-options .option .dot {
  height: 20px;
  width: 20px;
  margin-right: 1rem;
  background: #d9d9d9;
  border-radius: 50%;
  position: relative;
}

.pay-options .option .dot::before {
  position: absolute;
  content: "";
  top: 4px;
  left: 4px;
  width: 12px;
  height: 12px;
  background: #0069d9;
  border-radius: 50%;
  opacity: 0;
  transform: scale(1.5);
  transition: all 0.3s ease;
}

input[type="radio"] {
  display: none;
}

#cash:checked:checked~.cash,
#bank:checked:checked~.bank,
#Online:checked:checked~.Online {
  border-color: #0069d9;
  background: #0069d9;
}

#cash:checked:checked~.cash .dot,
#bank:checked:checked~.bank .dot,
#Online:checked:checked~.Online .dot {
  background: #fff;
}

#cash:checked:checked~.cash .dot::before,
#bank:checked:checked~.bank .dot::before,
#Online:checked:checked~.Online .dot::before {
  opacity: 1;
  transform: scale(1);
}

.pay-options .option span {
  font-size: 20px;
  color: #808080;
}

#cash:checked:checked~.cash span,
#bank:checked:checked~.bank span,
#Online:checked:checked~.Online span {
  color: #fff;
}
.payment-info-boxs {
  margin-top: 1.5rem;
}
.payment-info tr{
  border-bottom: 1px solid lightgrey;
}
.payment-info td{
  padding: .4rem;
}
.payment-info tr:last-child{
  border-bottom: none;
}
.payment-info tr td:last-child{
  font-weight: 700;
  padding-left: 1rem;
}
</style>
