<template>
  <div class="inner-section">
    <card>
      <!-- search section start -->
      <template v-slot:searchHeaderTitle>
        <h4 class="card-title">{{ $t('externalTradeFair.application') }} {{ $t('globalTrans.search') }}</h4>
      </template>
      <template v-slot:searchBody>
        <b-row>
          <b-col sm="4">
              <b-form-group
                label-for="year"
              >
              <template v-slot:label>
                {{$t('globalTrans.year')}}
              </template>
              <b-form-select
                plain
                v-model="search.year"
                :options="yearList"
                id="year"
              >
                <template v-slot:first>
                  <b-form-select-option disabled :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                </template>
              </b-form-select>
              </b-form-group>
          </b-col>
          <b-col sm="4">
              <b-form-group
                label-for="fair_id"
              >
              <template v-slot:label>
                {{ $t('ditfConfig.fair_name') }}
              </template>
              <b-form-select
                plain
                v-model="search.fair_id"
                :options="fairTitleList"
                id="fair_id"
              >
                <template v-slot:first>
                  <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                </template>
              </b-form-select>
              </b-form-group>
          </b-col>
          <b-col sm="4">
              <b-form-group
                label-for="circular_type"
              >
              <template v-slot:label>
                {{$t('ditfConfig.circular') + ' ' + $t('ditfConfig.type')}}
              </template>
              <b-form-select
                plain
                v-model="search.circular_type"
                :options="circularTypeList"
                id="circular_type"
              >
                <template v-slot:first>
                  <b-form-select-option disabled :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                </template>
              </b-form-select>
              </b-form-group>
          </b-col>
          <b-col sm="4">
            <b-button size="sm" variant="primary" class="" @click="searchData">
              <i class="ri-search-line"></i> {{ $t('globalTrans.search') }}
            </b-button>
          </b-col>
        </b-row>
      </template>
      <!-- search section end -->
    </card>

    <body-card>
      <!-- table section start -->
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('externalTradeFair.application') }} {{ $t('globalTrans.list') }}</h4>
      </template>
      <!-- <template v-slot:headerAction>
        <a href="javascript:" class="btn-add" @click="resetId" v-b-modal.modal-form><i class="ri-add-fill"></i> {{ $t('globalTrans.add_new') }}</a>
      </template> -->
      <template v-slot:body>
        <b-col>
          <b-alert variant="danger" v-model="showDismissibleAlert" dismissible fade class="alert alert-danger d-flex align-items-center">
            <div style="font-weight: bold"> Thanks. For complete counter payment, print the voucher & payment should be completed within 3 working days at any branch of Sonali Bank else cancel your application.</div>
          </b-alert>
        </b-col>
        <b-overlay :show="loadingState">
          <select-column-check v-on:editMethodInParent="editMethodInParent" :labelData="labelData" :search="search" :columns="columns" />
          <b-row>
            <b-col md="12" class="table-responsive" slot="padding-right: 30px">
              <slot v-if ="columns.filter(item => item.show === '1').length > 0">
                <b-table :sort-by.sync="sortBy" head-variant="primary" class="tg" :items="listData" :emptyText="$t('globalTrans.noDataFound')" show-empty bordered hover :fields="columns.filter(item => item.show === '1').sort((a, b) => { return a.order - b.order })">
                  <template v-slot:cell(serial)="data">
                    {{ $n(data.item.serial + pagination.slOffset) }}
                  </template>
                  <template v-slot:cell(year)="data">
                    <span>{{ $n(data.item.year, { useGrouping: false }) }}</span>
                  </template>
                  <template v-slot:cell(fair_id)="data">
                    {{ getSearchFairName(data.item.fair_id) }}
                  </template>
                  <template v-slot:cell(status)="data">
                    <span v-if="data.item.flag == 1" class="badge badge-danger">{{ $t('globalTrans.draft') }}</span>
                    <span v-else>
                      <span class="badge badge-info" v-if="data.item.status == 1">{{$t('globalTrans.pending')}}</span>
                      <span class="badge badge-success" v-if="data.item.status == 2">{{$t('globalTrans.approved')}}</span>
                      <span class="badge badge-danger" v-if="data.item.status == 3">{{$t('globalTrans.rejected')}}</span>
                      <span class="badge badge-warning" v-if="data.item.status == 4">{{$t('globalTrans.returned')}}</span>
                      <span class="badge badge-info" v-if="data.item.status == 5">{{$t('globalTrans.resubmit')}}</span>
                    </span>
                  </template>
                  <template v-slot:cell(pay_status)="data">
                    <span class="badge badge-danger" v-if="parseInt(data.item.pay_status) === 0">{{$t('globalTrans.unpaid')}}</span>
                    <span class="badge badge-warning" v-else-if="parseInt(data.item.pay_status) === 1">{{$t('globalTrans.processing_counter')}}</span>
                    <span class="badge badge-success" v-else>{{$t('globalTrans.paid')}}</span>
                    <!-- <span class="badge badge-warning" v-if="data.item.pay_status == 2">{{$t('globalTrans.refund')}}</span> -->
                  </template>
                  <template v-slot:cell(action)="data">
                    <a style="padding-left: 7px;padding-right: 7px;" class="btn btn-info" v-if="data.item.pay_status === 1 && data.item.sonali_transaction_id" target="_blank" :href="voucherDownload(data.item.sonali_transaction_id)">{{ $t('globalTrans.print_voucher') }}</a>
                    <a style="padding-left: 7px;padding-right: 7px;" class="btn btn-info" v-if="data.item.pay_status === 2 && data.item.sonali_transaction_id" target="_blank" :href="voucherDownload2(data.item.sonali_transaction_id)">{{ $t('globalTrans.print_voucher') }}</a>
                    <b-button :title="$t('globalTrans.details')" v-b-modal.modal-4 variant=" action-btn status" size="sm" @click="detailsData(data.item)"><i class="ri-eye-fill"></i></b-button>
                    <b-button v-if="data.item.flag === 1 || data.item.status == 4" variant=" iq-bg-success" size="sm" @click="editData(data.item)" class="action-btn edit" :title="$t('globalTrans.edit')"><i class="ri-pencil-fill"></i></b-button>

                    <b-button v-if="data.item.flag === 2 && data.item.status === 1 && data.item.pay_status === 0" v-b-modal.modal-payment @click="payment(data.item)" variant=" iq-bg-success" size="sm" class="action-btn edit blink_me" :title="$t('globalTrans.payment')"><i class="ri-secure-payment-line"></i></b-button>

                    <b-button v-if="data.item.pay_status !== 0" v-b-modal.payment-receipt @click="payment(data.item)" variant=" iq-bg-success" size="sm" class="action-btn edit" :title="$t('globalTrans.receipt')"><i class="ri-refund-line"></i></b-button>
                  </template>
                </b-table>
              </slot>
              <div class="pagination-wrapper mt-3" v-if="columns.filter(item => item.show === '1').length > 0">
                <b-pagination
                  v-model="pagination.currentPage"
                  :perPage="search.limit"
                  :total-rows="pagination.totalRows"
                  @input="searchData"
                />
                </div>
            </b-col>
          </b-row>
        </b-overlay>
      </template>
      <!-- table section end -->
    </body-card>
    <b-modal id="modal-form" size="xl" :title="formTitle" hide-footer :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <Form :id="detailsItemId" :key="detailsItemId"/>
    </b-modal>
    <b-modal id="modal-4" size="xl" hide-footer ok-only ok-variant="danger">
      <template #modal-title>
        {{ $t('externalTradeFair.application') + ' ' + $t('globalTrans.details') }}
        <b-button variant="primary" @click="pdfExport" class="mr-2 float-right">
          {{  $t('globalTrans.export_pdf') }}
        </b-button>
      </template>
      <Details :id="detailsItemId" :circular_type="circularTypeId" :key="detailsItemId" ref="details"/>
    </b-modal>
    <b-modal id="modal-payment" size="lg" :title="$t('globalTrans.payment') + ' ' + $t('globalTrans.details')" hide-footer :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <Payment :id="detailsItemId" :circular_type="circularTypeId" :year="yearData"/>
    </b-modal>
    <b-modal id="payment-receipt" size="lg" :title="$t('globalTrans.receipt')" hide-footer :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <template #modal-title>
        {{ $t('globalTrans.receipt') }}
        <b-button variant="primary" @click="pdfExportReceipt" class="mr-2 float-right">
          {{  $t('globalTrans.export_pdf') }}
        </b-button>
      </template>
      <Receipt :id="detailsItemId" :circular_type="circularTypeId" :year="yearData" ref="details"/>
    </b-modal>
  </div>
</template>
<script>
import RestApi, { internationalTradeFairServiceBaseUrl } from '@/config/api_config'
import Details from './Details'
import Form from './Form.vue'
import Payment from './payment/PaymentModal'
import Receipt from './payment/PaymentReeceipt'
import { DitfApplicationList, DitfOnlinePaymentUpdateProcess } from '../../../api/routes'
import ModalBaseMasterList from '@/mixins/list'
export default {
  mixins: [ModalBaseMasterList],
  components: {
    Details, Form, Payment, Receipt
  },
  data () {
    return {
      sortBy: '',
      detailsItemId: '',
      circularTypeId: '',
      yearData: '',
      selectAll: false,
      selectedItem: [],
      itemData: [],
      showDismissibleAlert: false,
      search: {
        year: 0,
        circular_type: 1,
        fair_id: 0,
        limit: 20
      },
      fairTitleList: [],
      labelData: [
        { labels: 'globalTrans.sl_no', class: 'text-center', sortable: true, stickyColumn: true, show: '1', order: 1, thStyle: { width: '6%' } },
        { labels: 'globalTrans.application_id', class: 'text-center', sortable: true, stickyColumn: true, show: '1', order: 2, thStyle: { width: '8%' } },
        { labels: 'globalTrans.year', class: 'text-center', sortable: true, stickyColumn: true, show: '1', order: 2, thStyle: { width: '15%' } },
        { labels: 'ditfTradeFairManages.circular_type', class: 'text-center', sortable: true, stickyColumn: true, show: '1', order: 3, thStyle: { width: '15%' } },
        { labels: 'ditfConfig.fair_name', class: 'text-center', sortable: true, stickyColumn: true, show: '1', order: 4, thStyle: { width: '15%' } },
        { labels: 'globalTrans.org_name', class: 'text-center', sortable: true, stickyColumn: true, show: '1', order: 4, thStyle: { width: '15%' } },
        { labels: 'globalTrans.status', class: 'text-center', sortable: true, stickyColumn: true, show: '1', order: 8, thStyle: { width: '15%' } },
        { labels: 'globalTrans.pay_status', class: 'text-center', sortable: true, stickyColumn: true, show: '1', order: 8, thStyle: { width: '25%' } },
        { labels: 'globalTrans.action', class: 'text-center', show: '1', order: 9, thStyle: { width: '20%' } }
      ],
      actions: {
        edit: true,
        details: false,
        toogle: true,
        delete: false
      }
    }
  },
  created () {
    // this.search.year = new Date().getFullYear()
    const currentFiscalYear = this.$store.state.CommonService.commonObj.fiscalYearList.find(item => item.value === this.$store.state.currentFiscalYearId)
    this.search.year = new Date(currentFiscalYear.end_date).getFullYear()
    if (this.$route.query.Token && this.$route.query.Mode) {
      if (this.$route.query.Mode === 'counter') {
        this.showDismissibleAlert = true
      }
      this.paymentStatusUpdate()
    } else {
      this.loadData()
    }
  },
  watch: {
    'search.year': function (newVal) {
      this.fairTitleList = this.dhakaFair()
    },
    'search.limit': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.loadData()
      }
    }
  },
  computed: {
    yearList () {
      return this.$store.state.commonObj.yearList.map(item => {
        if (this.$i18n.locale === 'bn') {
          return { value: item, text: this.$n(item, { useGrouping: false }) }
        } else {
          return { value: item, text: item }
        }
      })
    },
    circularTypeList () {
      return this.$store.state.TradeFairService.ditfCommonObj.circularTypeList.map(item => {
        return Object.assign({}, item, { text: this.$i18n.locale === 'en' ? item.text_en : item.text_bn })
      })
    },
    listData: function () {
      const tmpData = this.$store.state.list
      return JSON.parse(JSON.stringify(tmpData))
    },
    columns () {
      const labelData = this.labelData
      const labels = labelData.map((item, index) => {
        return Object.assign(item, { label: this.$t(item.labels, this.$i18n.locale) })
      })
      let keys = []
      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'serial' },
          { key: 'app_auto_id' },
          { key: 'year' },
          { key: 'circular_type_bn' },
          { key: 'fair_id' },
          { key: 'comp_name_bn' },
          { key: 'status' },
          { key: 'pay_status' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'serial' },
          { key: 'app_auto_id' },
          { key: 'year' },
          { key: 'circular_type_en' },
          { key: 'fair_id' },
          { key: 'comp_name_en' },
          { key: 'status' },
          { key: 'pay_status' },
          { key: 'action' }
        ]
      }
      return labels.map((item, index) => {
        return Object.assign(item, keys[index])
      })
    },
    formTitle () {
      return (this.detailsItemId === 0) ? this.$t('ditfConfig.stall_pavilion_restaurant') + ' ' + this.$t('globalTrans.entry') : this.$t('ditfConfig.stall_pavilion_restaurant') + ' ' + this.$t('globalTrans.modify')
    },
    currentLocale () {
      return this.$i18n.locale
    }
  },
  methods: {
    voucherDownload (voucher) {
      return 'https://voucher.sblesheba.com:8877/CounterReceipt/' + voucher
    },
    voucherDownload2 (voucher) {
      return 'https://voucher.sblesheba.com:8877/Receipt/' + voucher
    },
    editData (item) {
      this.$router.push(`/dhaka-trade-fair-panel/fair-application-form?id=${item.id}`)
    },
    payment (item) {
      this.detailsItemId = item.id
      this.circularTypeId = item.circular_type
      this.yearData = item.year
    },
    dhakaFair () {
      this.search.fair_id = 0
      return this.$store.state.ExternalUserService.tradeFair.ditfCommonObj.dhakaFairList.filter(item => item.year === this.search.year).map(item => {
        if (item !== '') {
          this.search.fair_id = item.value
        }
        if (this.$i18n.locale === 'bn') {
          return { value: item.value, text: item.text_bn }
        } else {
          return { value: item.value, text: item.text_en }
        }
      })
    },
    getFairTitle (year) {
      return this.$store.state.ExternalUserService.tradeFair.ditfCommonObj.dhakaFairList.filter(item => item.year === year).map(item => {
        if (this.$i18n.locale === 'bn') {
          return { value: item.value, text: item.text_bn }
        } else {
          return { value: item.value, text: item.text_en }
        }
      })
    },
    edit (item) {
      this.detailsItemId = item.id
    },
    editMethodInParent (item, type) {
      if (type === 1) {
        this.edit(item)
      }
    },
    resetId () {
      this.detailsItemId = 0
    },
    detailsData (item) {
      this.detailsItemId = item.id
      this.circularTypeId = item.circular_type
      this.item = item
    },
    async paymentStatusUpdate () {
        if (this.$route.query.Token && this.$route.query.Mode) {
          const queryParams = {
            Token: this.$route.query.Token,
            Mode: this.$route.query.Mode
          }
          await RestApi.getData(internationalTradeFairServiceBaseUrl, DitfOnlinePaymentUpdateProcess, queryParams).then(response => {
            if (response.success) {
              this.$toast.success({
                title: 'Success',
                message: response.message,
                color: '#D6E09B'
              })
            } else {
              this.showDismissibleAlert = false
              this.$toast.error({
                title: 'Error',
                message: response.message
              })
            }
          })
          this.loadData()
          this.$router.push('/dhaka-trade-fair-panel/fair-application')
        } else {
          this.loadData()
        }
    },
    searchData () {
      this.loadData()
    },
    async loadData () {
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      const result = await RestApi.getData(internationalTradeFairServiceBaseUrl, DitfApplicationList, params)
      if (result.success) {
        const listData = result.data.data.map((item, index) => {
          const circularTypeObj = this.$store.state.TradeFairService.ditfCommonObj.circularTypeList.find(tmp => tmp.value === item.circular_type)

          const circularTypeData = {
            circular_type_bn: circularTypeObj !== undefined ? circularTypeObj.text_bn : '',
            circular_type_en: circularTypeObj !== undefined ? circularTypeObj.text_en : ''
          }
          return Object.assign({}, item, { serial: index }, circularTypeData)
        })
        this.$store.dispatch('setList', listData)
        this.paginationData(result.data, this.search.limit)
      } else {
        this.$store.dispatch('setList', [])
        this.paginationData([])
      }
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    },
    getSearchFairName (id) {
      const companyType = this.$store.state.ExternalUserService.tradeFair.ditfCommonObj.dhakaFairList.find(obj => obj.value === parseInt(id))
      if (typeof companyType !== 'undefined') {
        return this.$i18n.locale === 'en' ? companyType.text_en : companyType.text_bn
      } else {
        return ''
      }
    },
    pdfExport () {
      this.$refs.details.pdfExport()
    },
    pdfExportReceipt () {
      this.$refs.details.pdfExport()
    }
  }
}
</script>
<style>
  .modal-title {
    flex-grow: 1 !important;
  }
</style>
<style scoped>
  .modal-title {
    flex-grow: 1 !important;
  }
  .blink_me {
    animation: blinker 2.5s linear infinite;
    color: red;
  }
  @keyframes blinker {
    50% {
      opacity: .09;
      /* background-color: green; */
    }
  }
</style>
